import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AbmCategoriasComponent } from './pages/abm-categoriasPV/abm-categorias.component';
import { AbmLocalComponent } from './pages/abm-local/abm-local.component';
import { AbmPuntosVentaComponent } from './pages/abm-puntos-venta/abm-puntos-venta.component';
import { AbmUsuarioComponent } from './pages/abm-usuario/abm-usuario.component';
import { AnularFacturasComponent } from './pages/anular-facturas/anular-facturas.component';
import { ConformacionMasivaComponent } from './pages/conformacion-masiva/conformacion-masiva.component';
import { EmisionMasivaComponent } from './pages/emision-masiva/emision-masiva.component';
import { EmisionComponent } from './pages/emision/emision.component';
import { ErroresComponent } from './pages/errores/errores.component';
import { HojaRutaInternaComponent } from './pages/hoja-ruta-interna/hoja-ruta-interna.component';
import { HomeComponent } from './pages/home/home.component';
import { RecepcionMasivaComponent } from './pages/recepcion-masiva/recepcion-masiva.component';
import { RecepcionComponent } from './pages/recepcion/recepcion.component';
import { ReimpresionguiasComponent } from './pages/reimpresionguias/reimpresionguias.component';
import { SeguimientoGuiasComponent } from './pages/seguimiento-guias/seguimiento-guias.component';
import { SeguimientoOrComponent } from './pages/seguimiento-or/seguimiento-or.component';
import { CallCenterReclamosComponent } from './pages/call-center-reclamos/call-center-reclamos.component';
import { GuiaControladoraComponent } from './pages/guia-controladora/guia-controladora.component';

const routes: Routes = [
  { path:'home', component:HomeComponent },
  { path: 'categorias', component:AbmCategoriasComponent},
  { path:'punto-venta', component:AbmPuntosVentaComponent},
  { path:'locales', component:AbmLocalComponent},
  { path:'usuarios', component:AbmUsuarioComponent},
  { path:'emision', component:EmisionComponent},
  { path:'cambiar-estado/recepcion', component:RecepcionComponent},
  { path:'cambiar-estado/conformacion', component:RecepcionComponent},
  { path:'cambiar-estado/anulacion', component:RecepcionComponent},
  { path:'recepcion-masiva', component:RecepcionMasivaComponent},
  { path:'conformacion-masiva', component:ConformacionMasivaComponent},
  { path:'anular-facturas', component:AnularFacturasComponent},
  { path:'hoja-ruta-interno', component:HojaRutaInternaComponent},
  { path:'errores', component:ErroresComponent},
  { path:'reimpresion', component:ReimpresionguiasComponent},
  { path:'seguimiento-guias', component:SeguimientoGuiasComponent},
  { path: 'emision-masiva', component:EmisionMasivaComponent},
  { path:'seguimiento-or', component:SeguimientoOrComponent},
  { path:'call-center-reclamos', component:CallCenterReclamosComponent},
  { path:'guia-controladora', component:GuiaControladoraComponent},
  
  { path:'**', pathMatch:'full', redirectTo:'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }