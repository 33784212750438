import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { logIn, LogInGet, Usuario } from 'src/app/models/Usuario';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  alert: boolean = false;
  ingreso:FormGroup
  usuarioVacio:boolean=false;
  claveVacia:boolean=false;
  loguin:boolean=true;
  usuario:Usuario;

  loading: boolean = false;
  constructor(private fb:FormBuilder, private usuarioService:UsuarioService,private router:Router) { }

  ngOnInit(): void {
    this.ingreso=this.fb.group({
      usuario:['', Validators.required],
      clave:['', Validators.required]
    });
    this.usuarioService.agregarUsuarioObservable.subscribe((user)=>{
      if(user===null){
        this.loguin=true;
      }else{
        this.loguin=false;
      }
    })
    if((this.usuario==null || this.usuario==undefined)){
      this.usuario=JSON.parse(sessionStorage.getItem('user'));
      if(this.usuario!=null){
        this.loguin=false;
      }else{
        this.loguin=true;
      }
    }
  }

  validarIngresoUsuario(evento){
    if(evento!==''){
      this.usuarioVacio=false;
    }
  }

  validarIngresoClave(evento){
    if(evento!==''){
      this.claveVacia=false;
    }
  }

  ingresar(){
    if(this.ingreso.controls['usuario'].value===''){
      this.usuarioVacio=true;
    }
    if(this.ingreso.controls['clave'].value===''){
      this.claveVacia=true;
    }
    if(this.ingreso.controls['usuario'].value!=='' && this.ingreso.controls['clave'].value!==''){
      //enviar para validar
      var usuario=this.ingreso.controls['usuario'].value;
      var pass=this.ingreso.controls['clave'].value;
      var datos:logIn={
        usuario:usuario,
        clave:pass
      }
  
      this.loading = true;
      this.usuarioService.getLogIn(datos).subscribe(res=>{
        if(res.Codigo==400){
          this.alert = true;
          alert(res.Mensaje);
          this.ingreso.controls['clave'].setValue(null);
          this.loading=false;
        }else{
        this.loguin=false;
        this.usuarioService.agregarUsuario(res.Usuario);
        sessionStorage.setItem('user', JSON.stringify(res.Usuario));
        this.loading=false;
        //window.location.href='emision';
        this.router.navigate(['/emision'])
        }
      })
      /* if(this.usuarioService.ingreso(usuario, pass)){
        this.loguin=false;
      }else{
        this.loguin=true;
      } */
    }
  }
  closeAlert(){
    this.alert = false
  };


}
