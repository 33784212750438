import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { Columns, PdfMakeWrapper, Table, Txt } from 'pdfmake-wrapper';
import { BuscarGuiaPost, ConfirmarHRIPost, EliminarGet, GuardarHRIPost, GuiaPendiente, HojaRutaInterno, HojaRutaInternoPost } from 'src/app/models/HojaRuta';
import { Local } from 'src/app/models/Local';
import { Usuario } from 'src/app/models/Usuario';
import { HojaRutaService } from 'src/app/services/hoja-ruta.service';
declare var $:any;
@Component({
  selector: 'app-hoja-ruta-interna',
  templateUrl: './hoja-ruta-interna.component.html',
  styleUrls: ['./hoja-ruta-interna.component.scss']
})
export class HojaRutaInternaComponent implements OnInit {

  @ViewChild('hojasRutaGrid', { static: false }) hojasRutaGrid: jqxGridComponent;
  p:number=1;
  usuario:Usuario
  local:Local={
    LocalID:0
  }
  agenciaConectadaID:number;
  hojasRutaApi:HojaRutaInterno[];
  hojasRutaFiltro:HojaRutaInterno[];
  hojaRutaInternoNew:HojaRutaInterno={
    HojaRutaInternoIDspk:0,
    EstadoHojaRutaInternoID:3
  };

  nroHojaRutaBusq:string="";
  cargando:boolean=true;
  cargandoEmision:boolean=false;

  nroHojaRutaInterno:string="";

  guiasPendiente:GuiaPendiente[];
  guiasSeleccionadas:GuiaPendiente[]=[];
  guiasBuscadas:GuiaPendiente[]=[];

  codigoBusq:string="";
  observaciones:string="";

  /* source: any =
    {
      datatype: 'json',
        datafields: [
          { name: 'HojaRutaInternoID'},
          { name: 'NroHojaRutaInterno'},
          { name: 'AgenciaDestinoDescrip'},
          { name: 'Fecha'},
          { name: 'EstadoHojaRutaInternoDescrip'},
          { name: 'Pendiente'}
        ]
    };
	getWidth() : any {
		if (document.body.offsetWidth < 800) {
			return '90%';
		}
		
		return 800;
	}
  dataAdapter: any;
    cellsrenderer = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
        if (value < 20) {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #ff0000;">' + value + '</span>';
        }
        else {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #008000;">' + value + '</span>';
        }
    };
    columns: any[] =
    [
      { text: 'HojaId', datafield: 'HojaRutaInternoID', width: 150, hidden:true},
      { text: 'Nro Hoja ruta interno', datafield: 'NroHojaRutaInterno', width: 150},
      { text: 'Agencia Destino', datafield: 'AgenciaDestinoDescrip', width: 150},
      { text: 'Fecha', datafield: 'Fecha', width: 150},
      { text: 'Estado', datafield: 'EstadoHojaRutaInternoDescrip', width: 150},
      { text: 'Editar', datafield: 'Editar', columntype: 'button', width: 100,
        cellsrenderer: (): string => {
          return 'Editar';
        },
        buttonclick: (row:number)=>{
          let dataRecord=this.hojasRutaGrid.getrowdata(row);
        }
      },
      { text: 'Eliminar', datafield: 'Pendiente', columntype: 'button', width: 100, 
        
        cellsrenderer: (index:number, datafield:boolean, value:any, defaultvalue:any, columnproperties: any, column:any, rowdata:any): string => {
          if(value===true)
            return '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/><path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/></svg>';
        },
        buttonclick: (row:number)=>{
          let dataRecord=this.hojasRutaGrid.getrowdata(row);
          if(dataRecord.Pendiente){
            var opcion=confirm("¿Está seguro que quiere deshabilitar el local?");
            if(opcion){
              
            }
          }
        }
      }
    ]; */

  constructor(public hojaRutaService:HojaRutaService, public router:Router) { }

  ngOnInit(): void {
    this.usuario=JSON.parse(sessionStorage.getItem('user'));
    if(this.usuario!=null && this.usuario!=undefined){
      for(var i=0; i<this.usuario.OpcionesMenu.length; i++){
        if(this.usuario.OpcionesMenu[i].Url=='hoja-ruta-interno'){
          this.local.LocalID=this.usuario.LocalID;
          this.agenciaConectadaID=this.usuario.agenciaID;
          
          break;
        }else if(i==this.usuario.OpcionesMenu.length-1){
          //window.location.href='home';
          this.router.navigate(['/home'])
        }
      }
      if(this.usuario.OpcionesMenu.length==0){
        this.router.navigate(['/home'])
      }
      /* 
      this.local.LocalID=this.usuario.LocalID;
      this.agenciaConectadaID=this.usuario.agenciaID; */
    }else{
      //window.location.href='home';
      this.router.navigate(['/home'])
    }
    this.cargarGrilla();
  }

 /*  mostrarGrid(){
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.cargando=false;
  } */

  cargarGrilla(){
    var datos:HojaRutaInternoPost={
      AgenciaID:this.agenciaConectadaID,
      HojaRutaInternoID:null
    }
    this.hojaRutaService.consultarHRI(datos).subscribe(res=>{
      if(res.Codigo==400){
        alert(res.Mensaje)
      }else{
        this.hojasRutaApi=res.hojasRuta;
        this.hojasRutaFiltro=res.hojasRuta;
        for(let hri of this.hojasRutaFiltro){
          if(hri.EstadoHojaRutaInternoDescrip=="Emitida"){
            hri.Pendiente=false;
          }else{
            hri.Pendiente=true;
          }
        }
        this.cargando=false;
        //this.source.localdata=this.hojasRutaFiltro;
        //this.mostrarGrid()
      }
    })
  }

  buscarHRI(){
    if(this.nroHojaRutaBusq!=null && this.nroHojaRutaBusq!=''){
      this.hojasRutaFiltro=this.hojasRutaApi.filter(hri=>{
        return hri.NroHojaRutaInterno.toString().includes(this.nroHojaRutaBusq);
      })
    }else{
      this.hojasRutaFiltro=this.hojasRutaApi;
    }
    /* this.source.localdata=this.hojasRutaFiltro;
    this.mostrarGrid(); */
  }

  seleccionar(HojaRuta:HojaRutaInterno){
    var datos:HojaRutaInternoPost={
      AgenciaID:this.agenciaConectadaID,
      HojaRutaInternoID:HojaRuta.HojaRutaInternoID
    }
    this.hojaRutaService.consultarHRI(datos).subscribe(res=>{
      if(res.Codigo==400){
        alert(res.Mensaje)
      }else{
        //abrir modal de nueva hoja ruta
      }
    })
  }

  buscarGuiasPendientes(){
    this.hojaRutaInternoNew={
      HojaRutaInternoIDspk:0,
      EstadoHojaRutaInternoID:3
    }
    this.nroHojaRutaInterno="";
    this.guiasSeleccionadas=[]
    var datos:HojaRutaInternoPost={
      AgenciaID:this.agenciaConectadaID,
      HojaRutaInternoID:null
    }
    this.hojaRutaService.consultarGuiasPendientes(datos).subscribe(res=>{
      if(res.Codigo==400){
        alert(res.Mensaje);
      }else{
        this.guiasPendiente=res.guiasPendientes;
        $("#modalNuevaHojaRutaInterna").modal("show");
      }
    })
  }

  seleccionarGuias(guia:GuiaPendiente, tipo:string, evento){
    if(tipo=='todos'){
      if(evento){
        //this.guiasSeleccionadas=this.guiasPendiente;
        if(this.guiasSeleccionadas.length==0){
          for(let g of this.guiasPendiente){
            $("#"+g.GuiaID).prop('checked', true);
            this.guiasSeleccionadas.push(g);
          }
        }else{
          for(let g of this.guiasPendiente){
            for(var i=0; i<this.guiasSeleccionadas.length;i++){
              if(this.guiasSeleccionadas[i].GuiaID==g.GuiaID){
                break;
              }else if(i==this.guiasSeleccionadas.length-1){
                $("#"+g.GuiaID).prop('checked', true);
                this.guiasSeleccionadas.push(g);
              }
            }
          }
        }
      }else{
        this.guiasSeleccionadas=[];
        for(let g of this.guiasPendiente){
          $("#"+g.GuiaID).prop('checked', false);
        }
      }
    }else{
      if(evento){
        this.guiasSeleccionadas.push(guia);
        if(this.guiasSeleccionadas.length==this.guiasPendiente.length){
          $("#todos").prop('checked', true);
        }
      } 
      else{
        for(var i=0; i<this.guiasSeleccionadas.length; i++){
          if(this.guiasSeleccionadas[i].GuiaID==guia.GuiaID){
            this.guiasSeleccionadas.splice(i, 1);
            break;
          }
        }
        $("#todos").prop('checked', false);
      }
        
    }
    console.log(this.guiasPendiente)
    console.log(this.guiasSeleccionadas)
  }

  guardarHRI(){
    var datos:GuardarHRIPost={
      AgenciaConectadaID:this.agenciaConectadaID,
      UsuarioID:this.usuario.usuarioID,
      HojaRutaInternoID:this.hojaRutaInternoNew.HojaRutaInternoID!=undefined?this.hojaRutaInternoNew.HojaRutaInternoID:0,
      Observaciones:this.observaciones,
      GuiasSeleccionadas:this.guiasSeleccionadas
    }
    this.cargandoEmision=true;
    this.hojaRutaService.guardarHRI(datos).subscribe(res=>{
      if(res.Codigo==400){
        alert(res.Mensaje)
        this.cargandoEmision=false;
      }else{
        //asignar nro hoja de ruta al input y mostrar boton confirmar
        this.hojaRutaInternoNew=res.hojaRuta;
        this.nroHojaRutaInterno=this.hojaRutaInternoNew.NroHojaRutaInterno.toString();
        this.cargandoEmision=false;
      }
    })
  }

  cerrarModal(){
    this.hojaRutaInternoNew={HojaRutaInternoID:0}
    $("#modalNuevaHojaRutaInterna").modal("hide");
    
    this.cargarGrilla();
  }

  buscarPorCodigo(){
    this.guiasBuscadas=[]
    $("#buscarGuiaPorCodigo").modal('show');
  }

  agregarGuias(){
    var cb:string;
    if(this.codigoBusq.substring(0,1)=="3"){
      cb="A"+this.codigoBusq.substring(1);
    }else if(this.codigoBusq.substring(0,1)=="4"){
      cb="B"+this.codigoBusq.substring(1);
    }else if(this.codigoBusq.substring(0,1)=="5"){
      cb="E"+this.codigoBusq.substring(1);
    }else if(this.codigoBusq.substring(0,1)=="9"){
      cb="R"+this.codigoBusq.substring(1);
    }else if(this.codigoBusq.substring(0,1)=="6"){
      cb="X"+this.codigoBusq.substring(1);
    }

    var datos:BuscarGuiaPost={
      codigo:cb,
      agenciaID:this.agenciaConectadaID
    }

    this.hojaRutaService.buscarGuia(datos).subscribe(res=>{
      if(res.Codigo==400){
        alert(res.Mensaje);
      }else{
        this.guiasBuscadas.push(res.guia);
        this.codigoBusq="";
      }
    })
  }
  
  eliminarGuia(guia:GuiaPendiente){
    for(var i=0; i<this.guiasBuscadas.length; i++){
      if(this.guiasBuscadas[i].GuiaID==guia.GuiaID){
        this.guiasBuscadas.splice(i, 1);
        break;
      }
    }
  }

  confirmar(){
    if(this.guiasBuscadas.length>0 && this.guiasPendiente.length>0){
      for(let gp of this.guiasPendiente){
        for(let gb of this.guiasBuscadas){
          if(gb.GuiaID==gp.GuiaID){
            $("#"+gp.GuiaID).prop('checked', true);
            if(this.guiasSeleccionadas.length>0){
              for(var i=0; i<this.guiasSeleccionadas.length; i++){
                if(this.guiasSeleccionadas[i].GuiaID==gb.GuiaID){
                  break;
                }else if(i==this.guiasSeleccionadas.length-1){
                  this.guiasSeleccionadas.push(gb);
                }
              }
            }else{
              this.guiasSeleccionadas.push(gb);
            }
          }
        }
      }
    }
    $("#buscarGuiaPorCodigo").modal('hide');
    console.log(this.guiasSeleccionadas)
  }

  cancelar(){
    $("#buscarGuiaPorCodigo").modal('hide');
  }

  editarHRI(hri:HojaRutaInterno){
    var datos:HojaRutaInternoPost={
      AgenciaID:this.agenciaConectadaID,
      HojaRutaInternoID:hri.HojaRutaInternoID,
      HojaRutaInternoIDspk:hri.HojaRutaInternoIDspk
    }
    this.hojaRutaService.editarHRI(datos).subscribe(res=>{
      if(res.Codigo==400){
        alert(res.Mensaje);
      }else{
        this.hojaRutaInternoNew=res.hojaRuta;
        this.hojaRutaInternoNew.GuiasAsignadas=res.guiasSeleccionadas;
        this.guiasPendiente=this.hojaRutaInternoNew.GuiasAsignadas;
                
        this.nroHojaRutaInterno=this.hojaRutaInternoNew.NroHojaRutaInterno.toString();
        for(let gp of this.guiasPendiente){
          for(let g of this.guiasSeleccionadas){
            if(gp.GuiaID==g.GuiaID)
              $("#"+gp.GuiaID).prop('checked', true);
          }
        }
        $("#modalNuevaHojaRutaInterna").modal("show");
      }
    })
  }

  confirmarHRI(){
    var datos:ConfirmarHRIPost={
      AgenciaConectadaID:this.agenciaConectadaID,
      UsuarioID:this.usuario.usuarioID,
      guiasSeleccionadas:this.guiasSeleccionadas,
      hojaRuta:this.hojaRutaInternoNew
    }
    this.hojaRutaService.confirmarHRI(datos).subscribe(res=>{
      if(res.Codigo==400){
        alert(res.Mensaje);
      }else{
        this.hojaRutaInternoNew=res.hojaRuta;
        
        this.cargarGrilla();
        
      }
    })
  }

  eliminarHRI(hri:HojaRutaInterno){
    var confirmacion=confirm("¿Está seguro que desea eliminar?");
    if(confirmacion){
      var datos:HojaRutaInternoPost={
        AgenciaID:this.agenciaConectadaID,
        HojaRutaInternoID:hri.HojaRutaInternoID,
        HojaRutaInternoIDspk:hri.HojaRutaInternoIDspk
      }
      this.hojaRutaService.eliminarHRI(datos).subscribe(res=>{
        if(res.Codigo==400){
          alert(res.Mensaje);
        }else{
          this.cargarGrilla();
        }
      })
    }
  }

  imprimir(){
    if(this.hojaRutaInternoNew.AgenciaDestinoDescrip==null){
      for(let hri of this.hojasRutaApi){
        if(hri.NroHojaRutaInterno==parseInt(this.nroHojaRutaInterno)){
          this.hojaRutaInternoNew.AgenciaDestinoDescrip=hri.AgenciaDestinoDescrip;
        }
      }
    }
    const pdf = new PdfMakeWrapper();
    var fecha=new Date(Date.now())
    pdf.add(new Columns([
      [new Txt(fecha.getDate().toString()+'/'+(fecha.getMonth()+1).toString()+'/'+fecha.getFullYear().toString()).fontSize(10).margin([0, 0, 0, 0]).end],
      [new Txt("Hoja Ruta Interna").fontSize(20).bold().alignment('center').margin([-250, 0, 0, 0]).end]
    ]).end)
    pdf.add(new Columns([
      [new Txt('NroHojaRutaInterno').fontSize(12).bold().margin([0, 20, 0, 0]).end],
      [new Txt(this.hojaRutaInternoNew.NroHojaRutaInterno.toString()).fontSize(12).margin([-130, 20, 0, 0]).end]
    ]).end)
    pdf.add(new Columns([
      [new Txt('Agencia Hija:').fontSize(12).bold().margin([0, 20, 0, 0]).end],
      [new Txt(this.usuario.AgenciaRazonSocial.toString()).fontSize(12).margin([-50, 20, 0, 0]).end],
      [new Txt('Agencia Madre:').fontSize(12).bold().margin([30, 20, 0, 0]).end],
      [new Txt(this.hojaRutaInternoNew.AgenciaDestinoDescrip.toString()).fontSize(12).margin([-10, 20, 0, 0]).end]
    ]).end)
    pdf.add(new Columns([
      [new Txt('Observaciones').fontSize(12).bold().margin([0, 20, 0, 0]).end],
      [new Txt(this.hojaRutaInternoNew.observaciones).fontSize(12).margin([0, 20, 0, 0]).end]
    ]).end)
    pdf.add(new Columns([
      [new Txt('______________________________________________________________________________________').fontSize(14).bold().margin([-10, 0, 0, 0]).end]
    ]).end)
    pdf.add(new Columns([
      [new Txt('CÓDIGO').fontSize(12).bold().alignment('center').margin([0, 10, 0, 0]).end],
      [new Txt('CLIENTE').fontSize(12).bold().alignment('center').margin([0, 10, 0, 0]).end],
      [new Txt('DESTINATARIO').fontSize(12).bold().alignment('center').margin([0, 10, 0, 0]).end],
      [new Txt('UNIDAD DE VENTA').fontSize(12).bold().alignment('center').margin([0, 10, 0, 0]).end],
      [new Txt('BULTOS').fontSize(12).bold().alignment('center').margin([0, 10, 0, 0]).end]
    ]).margin([-40, 0, -40, -5]).end)

    for(let g of this.hojaRutaInternoNew.GuiasAsignadas){
      pdf.add(new Columns([
        [new Txt(g.Codigo).fontSize(9).alignment('center').margin([0, 10, 0, 0]).end],
        [new Txt(g.ClienteDescrip).fontSize(9).alignment('center').margin([0, 10, 0, 0]).end],
        [new Txt(g.Destinatario).fontSize(9).alignment('center').margin([0, 10, 0, 0]).end],
        [new Txt(g.UnidadVentaDescrip).fontSize(9).alignment('center').margin([0, 10, 0, 0]).end],
        [new Txt(g.CantidadBultosTotal.toString()).fontSize(9).alignment('center').margin([0, 10, 0, 0]).end]
      ]).margin([-40, 0, -40, 0]).end)
    }

    pdf.create().open();
  }
}