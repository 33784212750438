import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NavComponent } from './components/nav/nav.component';
import { AbmCategoriasComponent } from './pages/abm-categoriasPV/abm-categorias.component';
import { AbmPuntosVentaComponent } from './pages/abm-puntos-venta/abm-puntos-venta.component';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { CommonModule, HashLocationStrategy, LocationStrategy }   from '@angular/common';
import { jqxCalendarModule }   from 'jqwidgets-ng/jqxcalendar';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AbmLocalComponent } from './pages/abm-local/abm-local.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalDomicilioComponent } from './components/modal-domicilio/modal-domicilio.component';
import { ModalResponsablesComponent } from './components/modal-responsables/modal-responsables.component';
import { EmisionComponent } from './pages/emision/emision.component';
import { ImpresionGuiaComponent } from './components/impresion-guia/impresion-guia.component';
import { OrdenRetiroComponent } from './components/orden-retiro/orden-retiro.component';
import { RecepcionComponent } from './pages/recepcion/recepcion.component';
import { ModalObservacionesComponent } from './components/modal-observaciones/modal-observaciones.component';
import { RecepcionMasivaComponent } from './pages/recepcion-masiva/recepcion-masiva.component';
import { ModalAgenciaComponent } from './components/modal-agencia/modal-agencia.component';
import { ConformacionMasivaComponent } from './pages/conformacion-masiva/conformacion-masiva.component';
import { ErroresComponent } from './pages/errores/errores.component';
import { ModalAutorizadosComponent } from './components/modal-autorizados/modal-autorizados.component';
import { AnularFacturasComponent } from './pages/anular-facturas/anular-facturas.component';
import { HojaRutaInternaComponent } from './pages/hoja-ruta-interna/hoja-ruta-interna.component';
import { AbmUsuarioComponent } from './pages/abm-usuario/abm-usuario.component';
import { ReimpresionguiasComponent } from './pages/reimpresionguias/reimpresionguias.component';
import { SeguimientoGuiasComponent } from './pages/seguimiento-guias/seguimiento-guias.component';
import { EmisionMasivaComponent } from './pages/emision-masiva/emision-masiva.component';
import { SeguimientoOrComponent } from './pages/seguimiento-or/seguimiento-or.component';


import { CallCenterReclamosComponent } from './pages/call-center-reclamos/call-center-reclamos.component';
import { AppSpinnersComponent } from './app-spinners/app-spinners.component';
import { GuiaControladoraComponent } from './pages/guia-controladora/guia-controladora.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    AbmCategoriasComponent,
    AbmPuntosVentaComponent,
    AbmLocalComponent,
    ModalDomicilioComponent,
    ModalResponsablesComponent,
    EmisionComponent,
    ImpresionGuiaComponent,
    OrdenRetiroComponent,
    RecepcionComponent,
    ModalObservacionesComponent,
    RecepcionMasivaComponent,
    ModalAgenciaComponent,
    ConformacionMasivaComponent,
    ErroresComponent,
    ModalAutorizadosComponent,
    AnularFacturasComponent,
    HojaRutaInternaComponent,
    AbmUsuarioComponent,
    ReimpresionguiasComponent,
    SeguimientoGuiasComponent,
    EmisionMasivaComponent,
    SeguimientoOrComponent,
    CallCenterReclamosComponent,
    AppSpinnersComponent,
    GuiaControladoraComponent,
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    jqxGridModule,
    CommonModule,
    jqxCalendarModule,
    jqxWindowModule,
    NgxPaginationModule,

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }