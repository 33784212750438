import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { getObservaciones, Observacion, ObservacionesPost } from '../models/Orden-Guia';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ObservacionService {
  obs:Observacion={};
  urlObservaciones:string=this.urlService.urlApi+"/api/Utiles/ObtenerObservaciones";

  private agregarObservacionSubject= new Subject<Observacion>();
  agregarObsObservable=this.agregarObservacionSubject.asObservable();

  constructor(private http:HttpClient, private urlService:UrlService) { }

  agregarObservacion(obs:Observacion){
    this.obs=obs;
    this.agregarObservacionSubject.next(obs);
  }

  getObservaciones(datos:ObservacionesPost):Observable<getObservaciones>{
    return this.http.post<getObservaciones>(this.urlObservaciones, datos);
  }
}