<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" [routerLink]="['home']">SISPACK 2.0</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">

        <!-- NUEVO MENÚ EMISIÓN-ANULACIÓN -->
        <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['emision']" [hidden]="usuario==null">Emisión</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Anulación
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
            <a class="dropdown-item" [routerLink]="['cambiar-estado/anulacion']">Anulación Guías</a>
            <a class="dropdown-item" [routerLink]="['anular-facturas']">Anulación Facturas</a>
          </div>
        </li> -->

        <!-- COMENTADO HASTA QUE SE PUBLIQUEN LAS OTRAS PANTALLAS -->
        <!-- <li class="nav-item dropdown" *ngFor="let opcion of menu">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{opcion.Titulo}}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
            <a class="dropdown-item" [routerLink]="[op.URL]" *ngFor="let op of opcion.Submenu" >{{op.Titulo}}</a>
          </div>
          
        </li> -->
        <!-- MENÚ DINÁMICO -->

     <!--   <li class="nav-item dropdown" *ngFor="let opcion of menu">

>>>>>>> e1e6189dc7d4187c121b1c533e7c191bde21fc8b
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{opcion.Titulo}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <div  *ngFor="let op of opcion.Submenu">
              <a class="dropdown-item" *ngIf="op.Submenu==undefined; else elseSubmenu"  [routerLink]="[op.Url]">{{op.Titulo}}</a>
              <ng-template #elseSubmenu>
                <li  class="dropdown-submenu" >
                  <a class="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#" >{{op.Titulo}}</a>
                  <ul class="dropdown-menu">
                    <a class="dropdown-item"  *ngFor="let o of op.Submenu" [routerLink]="[o.Url]">{{o.Titulo}}</a>
                  </ul>
          <ul>
            <a class="nav-link dropdown-toggle" [routerLink]="['emision-masiva']" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Emisión/Canalización masiva</a>
          </ul>
                </li>
              </ng-template>
            </div>
          </ul>
        </li>-->
        <!-- MENÚ ESTÁTICO -->

       <!--<li class="nav-item dropdown">

         <li class="nav-item dropdown">

          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [hidden]="usuario==null">
            Configuración
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown" >
            <a class="dropdown-item" [routerLink]="['categorias']">ABM Categorias</a>
            <a class="dropdown-item" [routerLink]="['punto-venta']">ABM Punto Venta</a>
            <a class="dropdown-item" [routerLink]="['locales']">ABM Locales</a>
            <a class="dropdown-item" [routerLink]="['usuarios']">ABM Usuarios</a>
          </ul>
        </li> -->
        <li class="nav-item dropdown" >
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [hidden]="usuario==null">
            Seguimiento
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" [routerLink]="['seguimiento-guias']">Seguimiento</a></li>
            <li><a class="dropdown-item" [routerLink]="['seguimiento-or']">Seguimiento Órdenes</a></li>
          </ul>
          </li>

        <li class="nav-item dropdown" >
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [hidden]="usuario==null">
            Gestión
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li><a class="dropdown-item" [routerLink]="['emision-masiva']">Emisión/Canalización Masiva</a></li>
  
            <li><a class="dropdown-item" [routerLink]="['emision']">Emisión</a></li>
         <!-- <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#">Recepción</a>
              <ul class="dropdown-menu">
                <a class="dropdown-item" [routerLink]="['cambiar-estado/recepcion']">Recepción</a>
                <a class="dropdown-item" [routerLink]="['recepcion-masiva']">Recepción Masiva</a>
              </ul>
            </li>-->
            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#">Conformación</a>
              <ul class="dropdown-menu">
                <a class="dropdown-item" [routerLink]="['cambiar-estado/conformacion']">Conformación</a>
                <a class="dropdown-item" [routerLink]="['conformacion-masiva']">Conformación Masiva</a>
              </ul>
            </li>
            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#">Anulación</a>
              <ul class="dropdown-menu">
                <a class="dropdown-item" [routerLink]="['cambiar-estado/anulacion']">Anulación Guías</a>
                <a class="dropdown-item" [routerLink]="['anular-facturas']">Anulación Facturas</a>
              </ul>
            </li>
            <li><a class="dropdown-item" [routerLink]="['hoja-ruta-interno']">Hoja Ruta Interna</a></li>
            <li><a class="dropdown-item" [routerLink]="['reimpresion']">ReImpresión de Guías</a></li>
            <li><a class="dropdown-item" [routerLink]="['guia-controladora']">Generación de Guia Contenedora</a></li>
          </ul>
        </li> 
        
        <li class="nav-item dropdown" >
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [hidden]="usuario==null">
            Call Center Reclamos
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" [routerLink]="['call-center-reclamos']">Call Center Reclamos</a></li>
           
          </ul>
          </li>
        
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Dropdown
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </li> -->
      </ul>
      <!-- <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
      </form> -->
    </div>
    <!-- <div class="nav-item" style="justify-content: end;">
      <a class="btn btn-outline-info rounded-pill" href="https://soporteandesmarcargas.atlassian.net/servicedesk/customer/portals" target="_blank" [hidden]="usuario==null">Soporte</a>
    </div> -->
    <div class="img" style="display: flex; justify-content: end; width: 75%; margin-left: auto;">
      <a class="btn btn-outline-info rounded-pill" href="https://soporteandesmarcargas.atlassian.net/servicedesk/customer/portals" style="margin-right: 20px; margin-bottom: 5px;" target="_blank">Soporte</a>
      <button class="btn btn-link" (click)="salir()" *ngIf="mostrarOpciones" style="margin-right: 20px;">Salir</button>
      <img src="/assets/AndesmarCargas-img.webp" alt="logo Andesmar Cargas" style="width:20%; margin-bottom: 5px;">
    </div>
    <!-- <div style="margin-left: 15px;">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div> -->
  </nav>
