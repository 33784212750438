import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/Usuario';
import { Router } from '@angular/router';
import { GuiaControladoraService } from 'src/app/services/guia-controladora.service';
import { CndActivos,  GuiaContenedoraGuardado, datosEmisionGC } from 'src/app/models/guiaContenedora';
import Swal from 'sweetalert2';
import { DatosImpresion, EmitirGuia,  EmitirGuiaGCGet,  EmitirGuiaGet,  GuardadoPdfPost,  GuiaEmitida, GuiaEmitidaGC } from 'src/app/models/Orden-Guia';
import { EmisionService } from 'src/app/services/emision.service';
import { Canvas, Cell, Columns, Img, Line, PdfMakeWrapper, Table, Txt } from 'pdfmake-wrapper';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
declare var $: any;
@Component({
  selector: 'app-guia-controladora',
  templateUrl: './guia-controladora.component.html',
  styleUrls: ['./guia-controladora.component.scss']
})
export class GuiaControladoraComponent implements OnInit {
  usuario: Usuario;
  cargandoCodigo: boolean = false;
  codigoPedido: string;
  mensaje: string;
  cargandoPedido: boolean = false;
  finalizandopedido: boolean = false;
  volver: boolean = false;
  isDisabled: boolean = false;
  ishidden: boolean = true;
  pedidosError: string[] = [];
  pedidosasignados: string[] = [];
  validado: boolean = false;
  pedidosfaltan: string[] = [];
  masDetalle: boolean = false;
  guiaAEnviarOK: EmitirGuia[] = []; 
  guiasEmitidas: GuiaEmitidaGC[] = [];
  datosImpresion: EmitirGuiaGCGet[] = [];
  constructor(private router: Router, private guiaControladora: GuiaControladoraService,
    private emisionService: EmisionService) {

    this.TraerCND();
  }

  guiacontroladoralista: CndActivos[] = [];
  ngOnInit(): void {
    this.usuario = JSON.parse(sessionStorage.getItem('user'));
    if (this.usuario != null && this.usuario != undefined) {
      for (var i = 0; i < this.usuario.OpcionesMenu.length; i++) {

        if (this.usuario.OpcionesMenu[i].Url == 'guia-controladora') {
          $("#DivValidaPaquete").hide();

       
          break;
        } else if (i == this.usuario.OpcionesMenu.length - 1) {

          this.router.navigate(['/home'])
        }
      }
      if (this.usuario.OpcionesMenu.length == 0) {
        this.router.navigate(['/home'])
      }
    } else {

      this.router.navigate(['/home'])
    }
  }

  TraerCND() {
    this.isDisabled = false;

    //  $("#slect").html("");
    $("#DivValidaPaquete").hide();
    this.codigoPedido = "";
    this.guiaControladora.getCndActivos().subscribe(res2 => {
      this.guiacontroladoralista = res2.CndActivos;
    });
  }
  
  enviarCodigo() {
    this.cargandoPedido = true;
    var nombre = $("#slect option:selected").text();
    var id = $("#slect").val();

    var datos: GuiaContenedoraGuardado = {
      sucursal: nombre,
      AgenciaDestinoID: id,
      NroRemito: this.codigoPedido,
      UsuarioID: this.usuario.usuarioID
    }
    this.guiaControladora.guardarGC(datos).subscribe(res2 => {
      this.mensaje = res2.Mensaje;
      //var pp= res2.Mensaje;
       this.codigoPedido="";
      this.ishidden = false;
      this.cargandoPedido = false;
    });
  }
  validarOperacion() {
    var nombre = $("#slect option:selected").text();
    var id = $("#slect").val();
    Swal.fire({
      title: "¿Desea validar la operación?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        $("#DivValidaPaquete").hide();
        this.guiaControladora.ValidaOperacionGC(this.usuario.usuarioID, id).subscribe(res => {
          if (res.Codigo == 200) {
            this.pedidosasignados = res.pedidosasignados;
            this.pedidosError = res.pedidosError;

            if(this.pedidosasignados.length==0)
            {
              $("#btnFinaliza").hide();  
            }
          }
        }); 
      }
    });
  }

  seleccionaCND() {
    this.isDisabled = true;
    $("#DivValidaPaquete").show();
    $("#divCND").hide()
    var nombre = $("#slect option:selected").text();
    var id = $("#slect").val();
    $("#ingresonombre").text("Ingrese paquetes con destino al : " + nombre)
  }
  limpiarCND() {
    $("#divCND").show()
    this.TraerCND();

  }
  enviarEmail() { }
  imprimirGuias() { }

  Volver(){
window.location.reload();

  }
  finalizar() {
   this.finalizandopedido=true;
   this.volver=true;
    this.guiaAEnviarOK=[];
    var id = $("#slect").val();
    var datos: datosEmisionGC = {
     UsuarioID:this.usuario.usuarioID,
     AgenciaDesintoGC:id,
AgenciaOrigenGC:this.usuario.agenciaID
    }
  /*  this.guiaControladora.EmitirguiaGC(datos).subscribe(res2 => {

      alert("R"+ res2.guiasEmitidas[0].nroSucursalDGI + res2.guiasEmitidas[0].nroGuia );
      this.finalizandopedido=false;
    this.imprimir(res2)
  });*/
  this.guiaControladora.EmitirguiaGC(datos).subscribe(res2 => {
    Swal.fire({
      title: "¡Guía Emitida!",
      text: " R" + res2.guiasEmitidas[0].nroSucursalDGI + res2.guiasEmitidas[0].nroGuia,
      icon: "success",
      showCancelButton: true,
      cancelButtonText: "Imprimir",
      confirmButtonText: "Aceptar",
      reverseButtons: true
    }).then((result) => {
      this.finalizandopedido = false; 
      if (result.isConfirmed) {
      window.location.reload();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        const origen =this.usuario.AgenciaRazonSocial;// $("#slect option:selected").text();
        const destino = res2.guiasEmitidas[0].LocalDest.LocalDescrip;
        const cantidadBultos = res2.guiasEmitidas[0].CalculoImporteGuia.CantBultos.toString();
        const src='data:image/jpeg;base64,' + res2.guiasEmitidas[0].nombreImgCB;//res2.guiasEmitidas[0].nombreImgCB
        const codigo = ` R-${res2.guiasEmitidas[0].nroSucursalDGI}-${res2.guiasEmitidas[0].nroGuia}`;
        const cantidadPedidos=res2.guiasEmitidas[0].CantidadDeGuias.toString();
        Swal.fire({
          title: "Guia Contenedora",
          html:
            `<strong>ORIGEN: </strong> ${origen}<br>` +
            `<strong>DESTINO: </strong> ${destino}<br>` +
            `<strong>CANTIDAD DE PEDIDOS: </strong> ${cantidadBultos} <br>` +
            `<strong>CANTIDAD DE BULTOS: </strong>${cantidadPedidos}<br>` +
            `<img src=${src}><br>` +
            `<strong>GC :</strong> ${codigo}`,
          showCancelButton: false,
          showCloseButton:false,
          showConfirmButton:false,
          customClass: {
            container: 'custom-modal-container',
            title: 'custom-modal-title',
            htmlContainer: 'custom-modal-html-container',
          },
          buttonsStyling: false,
        });
        setTimeout(function(){
          window.print();
          
      }, 2000);
      setTimeout(function(){
        window.location.reload();
        
    }, 6000);
      }
     
     
      
    });
   
  });
 
}





}




