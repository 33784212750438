import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgenciaPVLocal, Local } from 'src/app/models/Local';
import { GuardarOpcionPost, Opcion, Usuario, UsuarioPost } from 'src/app/models/Usuario';
import { LocalService } from 'src/app/services/local.service';
import { UsuarioService } from 'src/app/services/usuario.service';
declare var $:any;
@Component({
  selector: 'app-abm-usuario',
  templateUrl: './abm-usuario.component.html',
  styleUrls: ['./abm-usuario.component.scss']
})
export class AbmUsuarioComponent implements OnInit {
  usuario:Usuario
  p:number=1;
  cargandoNuevo:boolean=false;
  usuariob:string;
  nombre:string;
  apellido:string;
  cargando:boolean=false;
  cargandoPerfil:boolean=false;
  agencia:string='0';
  puntoVenta:string;
  local:string='0';
  agencias:AgenciaPVLocal[];
  agenciasPV:AgenciaPVLocal[]=[];
  locales:Local[]=[];

  usuarios:Usuario[]
  usuariosFiltro:Usuario[]
  usuarioN:Usuario={}

  opciones:Opcion[];
  opcionesSeleccionadas:string[]=[];
  //usuario nuevo
  nombreNuevo:string;
  apellidoNuevo:string;
  loginNuevo:string;
  emailNuevo:string;
  agenciaNuevo:string='0';
  pvNuevo:string;
  localNuevo:string='0';
  estadoNuevo:string='0';
  unidadNegocio:string='0';
  agenciasN:AgenciaPVLocal[];
  agenciasPVN:AgenciaPVLocal[]=[];
  localesN:Local[]=[];

  constructor(private usuarioService:UsuarioService, private localService:LocalService, private router:Router) { }

  ngOnInit(): void {
    this.usuario=JSON.parse(sessionStorage.getItem('user'));
    if(this.usuario!=null && this.usuario!=undefined){
      for(var i=0; i<this.usuario.OpcionesMenu.length; i++){
        if(this.usuario.OpcionesMenu[i].Url=='usuarios'){
          break;
        }else if(i==this.usuario.OpcionesMenu.length-1){
          //window.location.href='home';
          this.router.navigate(['/home'])
        }
      }
      if(this.usuario.OpcionesMenu.length==0){
        this.router.navigate(['/home'])
      }
    }else{
      //window.location.href='home';
      this.router.navigate(['/home'])
    }
    this.cargarGrilla();
    this.localService.getAgenciasPVLocal().subscribe(res=>{
      if(res.Codigo==200){
        this.agencias=res.agencias;
        this.agenciasN=res.agencias;
      }else{
        alert(res.Mensaje);
      }
    })
    this.usuarioService.getOpciones().subscribe(res=>{
      if(res.Codigo==400){
        alert(res.Mensaje);
      }else{
        this.opciones=res.opciones;  
      }
    })
  }

  cargarGrilla(){
    this.cargando=true;
    this.usuarioService.getUsuarios().subscribe(res=>{
      if(res.Codigo==200){
        this.usuarios=res.usuarios;
        this.usuariosFiltro=res.usuarios;
        this.cargando=false;
      }else{
        alert(res.Mensaje);
        this.cargando=false;
      }
    })
  }

  seleccionarAgencia(evento, pantalla:string){
    if(pantalla=='consulta'){
      this.locales=[];
      this.agenciasPV=[];
      var agencia=this.agencias.filter(p=>{
        return p.AgenciaID==evento;
      })
      this.agenciasPV.push(agencia[0]);
      this.locales=agencia[0].locales;
      this.local='0';
    }else{
      this.localesN=[];
      this.agenciasPVN=[];
      var agenciaN=this.agenciasN.filter(p=>{
        return p.AgenciaID==evento;
      })
      this.agenciasPVN.push(agenciaN[0]);
      this.pvNuevo=agenciaN[0].PuntoVentaID.toString();
      this.localesN=agenciaN[0].locales;
      this.localNuevo='0';
    }
  }

  buscarUsuario(campo:string, event:string){
    if(campo=='usuario'){
      if((this.nombre==null || this.nombre=='') && 
      (this.apellido==null || this.apellido=='') && 
      (this.agencia==null || this.agencia=='0') &&
      (this.puntoVenta==null || this.puntoVenta=='') &&
      (this.local==null || this.local=='0')){
        this.usuariosFiltro=this.usuarios.filter(usu=>{
          return usu.login.toLowerCase().includes(event.toLowerCase());
        })
      }else{
        var i=0;
        if(this.nombre!=null && this.nombre!=''){
          i++;
          this.usuariosFiltro=this.usuarios.filter(usu=>{
            return usu.nombre.toLowerCase().includes(this.nombre.toLowerCase());
          })
        }
        if(this.apellido!=null && this.apellido!=''){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.apellido.toLowerCase().includes(this.apellido.toLowerCase());
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.apellido.toLowerCase().includes(event.toLowerCase());
            })
          }
        }
        if(this.agencia!=null && this.agencia!='0'){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.agenciaID.toString()==this.agencia;
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.agenciaID.toString()==this.agencia;
            })
          }
        }
        if(this.local!=null && this.local!='0'){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.LocalID.toString()==this.local;
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.LocalID.toString()==this.local;
            })
          }
        }
        this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
          return usu.login.toLowerCase().includes(event.toLowerCase());
        })
      }
    }else if(campo=='nombre'){
      if((this.usuariob==null || this.usuariob=='') && 
      (this.apellido==null || this.apellido=='') && 
      (this.agencia==null || this.agencia=='0') &&
      (this.puntoVenta==null || this.puntoVenta=='') &&
      (this.local==null || this.local=='0')){
        this.usuariosFiltro=this.usuarios.filter(usu=>{
          return usu.nombre.toLowerCase().includes(event.toLowerCase());
        })
      }else{
        var i=0;
        if(this.usuariob!=null && this.usuariob!=''){
          i++;
          this.usuariosFiltro=this.usuarios.filter(usu=>{
            return usu.login.toLowerCase().includes(this.usuariob.toLowerCase());
          })
        }
        if(this.apellido!=null && this.apellido!=''){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.apellido.toLowerCase().includes(this.apellido.toLowerCase());
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.apellido.toLowerCase().includes(this.apellido.toLowerCase());
            })
          }
        }
        if(this.agencia!=null && this.agencia!='0'){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.agenciaID.toString()==this.agencia;
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.agenciaID.toString()==this.agencia;
            })
          }
        }
        if(this.local!=null && this.local!='0'){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.LocalID.toString()==this.local;
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.LocalID.toString()==this.local;
            })
          }
        }
        this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
          return usu.nombre.toLowerCase().includes(event.toLowerCase());
        })
      }
    }else if(campo=='apellido'){
      if((this.usuariob==null || this.usuariob=='') && 
      (this.nombre==null || this.nombre=='') && 
      (this.agencia==null || this.agencia=='0') &&
      (this.puntoVenta==null || this.puntoVenta=='') &&
      (this.local==null || this.local=='0')){
        this.usuariosFiltro=this.usuarios.filter(usu=>{
          return usu.apellido.toLowerCase().includes(event.toLowerCase());
        })
      }else{
        var i=0;
        if(this.usuariob!=null && this.usuariob!=''){
          i++;
          this.usuariosFiltro=this.usuarios.filter(usu=>{
            return usu.login.toLowerCase().includes(this.usuariob.toLowerCase());
          })
        }
        if(this.nombre!=null && this.nombre!=''){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.nombre.toLowerCase().includes(this.nombre.toLowerCase());
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.nombre.toLowerCase().includes(this.nombre.toLowerCase());
            })
          }
        }
        if(this.agencia!=null && this.agencia!='0'){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.agenciaID.toString()==this.agencia;
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.agenciaID.toString()==this.agencia;
            })
          }
        }
        if(this.local!=null && this.local!='0'){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.LocalID.toString()==this.local;
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.LocalID.toString()==this.local;
            })
          }
        }
        this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
          return usu.apellido.toLowerCase().includes(event.toLowerCase());
        })
      }
    }else if(campo=='agencia'){
      if((this.usuariob==null || this.usuariob=='') && 
      (this.nombre==null || this.nombre=='') && 
      (this.apellido==null || this.apellido=='') &&
      (this.puntoVenta==null || this.puntoVenta=='') &&
      (this.local==null || this.local=='0' || this.local=='')){
        this.usuariosFiltro=this.usuarios.filter(usu=>{
          return usu.agenciaID.toString()==event;
        })
      }else{
        var i=0;
        if(this.usuariob!=null && this.usuariob!=''){
          i++;
          this.usuariosFiltro=this.usuarios.filter(usu=>{
            return usu.login.toLowerCase().includes(this.usuariob.toLowerCase());
          })
        }
        if(this.nombre!=null && this.nombre!=''){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.nombre.toLowerCase().includes(this.nombre.toLowerCase());
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.nombre.toLowerCase().includes(this.nombre.toLowerCase());
            })
          }
        }
        if(this.apellido!=null && this.apellido!=''){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.apellido.toLowerCase().includes(this.apellido.toLowerCase());
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.apellido.toLowerCase().includes(this.apellido.toLowerCase());
            })
          }
        }
        if(this.local!=null && this.local!='0' && this.local!=''){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.LocalID.toString()==this.local;
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.LocalID.toString()==this.local;
            })
          }
        }
        this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
          return usu.agenciaID.toString()==event;
        })
      }
    }else if(campo=='local'){
      if((this.usuariob==null || this.usuariob=='') && 
      (this.nombre==null || this.nombre=='') && 
      (this.apellido==null || this.apellido=='') &&
      (this.puntoVenta==null || this.puntoVenta=='') &&
      (this.agencia==null || this.agencia=='0' || this.agencia=='')){
        this.usuariosFiltro=this.usuarios.filter(usu=>{
          return usu.LocalID.toString()==event;
        })
      }else{
        var i=0;
        if(this.usuariob!=null && this.usuariob!=''){
          i++;
          this.usuariosFiltro=this.usuarios.filter(usu=>{
            return usu.login.toLowerCase().includes(this.usuariob.toLowerCase());
          })
        }
        if(this.nombre!=null && this.nombre!=''){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.nombre.toLowerCase().includes(this.nombre.toLowerCase());
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.nombre.toLowerCase().includes(this.nombre.toLowerCase());
            })
          }
        }
        if(this.apellido!=null && this.apellido!=''){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.apellido.toLowerCase().includes(this.apellido.toLowerCase());
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.apellido.toLowerCase().includes(this.apellido.toLowerCase());
            })
          }
        }
        if(this.agencia!=null && this.agencia!='0' && this.agencia!=''){
          if(i==0){
            i++;
            this.usuariosFiltro=this.usuarios.filter(usu=>{
              return usu.agenciaID.toString()==this.agencia;
            })
          }else{
            this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
              return usu.agenciaID.toString()==this.agencia;
            })
          }
        }
        this.usuariosFiltro=this.usuariosFiltro.filter(usu=>{
          return usu.LocalID.toString()==event;
        })
      }
    }
  }

  abrirModal(usuario:Usuario){
    if(usuario!=null){
      this.usuarioN=usuario;
      this.nombreNuevo=usuario.nombre;
      this.apellidoNuevo=usuario.apellido;
      this.loginNuevo=usuario.login;
      this.emailNuevo=usuario.email;
      this.agenciaNuevo=usuario.agenciaID.toString();
      var agenciaN=this.agenciasN.filter(p=>{
        return p.AgenciaID==usuario.agenciaID;
      })
      this.agenciasPVN.push(agenciaN[0]);
      this.localesN=agenciaN[0].locales;
      this.pvNuevo=usuario.PuntoVentaID.toString();
      this.localNuevo=usuario.LocalID.toString();
      this.estadoNuevo=usuario.estadoUsuarioPerfilID.toString();
      this.unidadNegocio=usuario.unidadNegocioID.toString();
    }
    $("#modalNuevoUsuario").modal("show") ;
  }

  cerrarModal(){
    this.usuarioN={};
    this.nombreNuevo='';
    this.apellidoNuevo='';
    this.loginNuevo='';
    this.emailNuevo='';
    this.estadoNuevo='0'; 
    this.agenciaNuevo='0';
    this.pvNuevo='';
    this.localNuevo='0';
    this.localesN=[];
    this.agenciasPVN=[];
    $("#modalNuevoUsuario").modal("hide") ;
  }

  guardarUsuario(){
    var usuarioNuevo:Usuario={
      nombre:this.nombreNuevo,
      apellido:this.apellidoNuevo,
      login:this.loginNuevo,
      clave:this.loginNuevo,
      email:this.emailNuevo,
      agenciaID:parseInt(this.agenciaNuevo),
      PuntoVentaID:parseInt(this.pvNuevo),
      LocalID:parseInt(this.localNuevo),
      estadoUsuarioPerfilID:parseInt(this.estadoNuevo),
      unidadNegocioID:parseInt(this.unidadNegocio)
    }
    if(this.usuarioN!=null){
      usuarioNuevo.usuarioID=this.usuarioN.usuarioID;
    }
    var datos:UsuarioPost={
      usuario:usuarioNuevo
    }
    this.usuarioService.guardarUsuario(datos).subscribe(res=>{
      alert(res.Mensaje);
      if(res.Codigo==200){
        //this.cargarGrilla();
        for(var i=0; i<this.usuarios.length; i++){
          if(this.usuarios[i].usuarioID==res.usuario.usuarioID){
            this.usuarios.splice(i, 1);
            this.usuarios.push(res.usuario);
            break;
          }else if(i==this.usuarios.length-1){
            this.usuarios.push(res.usuario);
          }
        }
        for(var i=0; i<this.usuariosFiltro.length; i++){
          if(this.usuariosFiltro[i].usuarioID==res.usuario.usuarioID){
            this.usuariosFiltro.splice(i, 1);
            this.usuariosFiltro.push(res.usuario);
            break;
          }else if(i==this.usuarios.length-1){
            this.usuariosFiltro.push(res.usuario);
          }
        }
        this.cerrarModal();
        this.usuariob='';
        this.nombre='';
        this.apellido='';
        this.agenciasPV=[];
        this.locales=[];
        this.agencia='0';
        this.local='0';
        
        this.usuariosFiltro=this.usuarios;
      }
    })
  }

  eliminarUsuario(usuarioId:number){
    var confirmacion=confirm("¿Está seguro que desea eliminar?");
    if(confirmacion){
      var datos:UsuarioPost={
        usuarioID:usuarioId
      }
      this.usuarioService.eliminarUsuario(datos).subscribe(res=>{
        if(res.Codigo==400){
          alert(res.Mensaje);
        }else{
          alert("Usuario Eliminado");
          this.usuariob='';
          this.nombre='';
          this.apellido='';
          this.agenciasPV=[];
          this.locales=[];
          this.agencia='0';
          this.local='0';
          for(var i=0; i<this.usuarios.length; i++){
            if(this.usuarios[i].usuarioID==usuarioId){
              this.usuarios.splice(i, 1);
              break;
            }
          }
          for(var i=0; i<this.usuariosFiltro.length; i++){
            if(this.usuariosFiltro[i].usuarioID==usuarioId){
              this.usuariosFiltro.splice(i, 1);
              break;
            }
          }
          this.usuariosFiltro=this.usuarios;
          //this.cargarGrilla();
        }
      })
    }
  }

  abrirModalOpciones(usuario:Usuario){
    this.usuarioN=usuario;
    if(this.usuarioN.opcionesID!=null && this.usuarioN.opcionesID!=undefined){
      for(let o of this.usuarioN.opcionesID){
        for(let op of this.opciones){
          if(o==op.OpcionID){
            $("#"+op.OpcionID).prop('checked', true);
            this.opcionesSeleccionadas.push(op.OpcionID);
            break;
          }
        }
      }
    }
    
    $("#modalAsignacionPantallaUsuario").modal("show");
  }

  cerrarModalOpciones(){
    this.usuarioN={}
    $("#modalAsignacionPantallaUsuario").modal("hide");
  }

  agregarOpciones(evento, opcionId:string){
    if(evento.target.checked){
      this.opcionesSeleccionadas.push(opcionId);
    }else{
      for(var i=0; i<this.opcionesSeleccionadas.length; i++){
        if(this.opcionesSeleccionadas[i]==opcionId)
          this.opcionesSeleccionadas.splice(i, 1);
      }
    }
  }

  guardarOpcionUsuario(){
    var datos:GuardarOpcionPost={
      opciones:this.opcionesSeleccionadas,
      usuarioID:this.usuarioN.usuarioID
    }
    this.usuarioService.getGuardarOpcionUsuario(datos).subscribe(res=>{
      if(res.Codigo==400){
        alert(res.Mensaje);
      }else{
        alert(res.Mensaje);
        $("#modalAsignacionPantallaUsuario").modal("hide");
        for(let u of this.usuariosFiltro){
          if(u.usuarioID==this.usuarioN.usuarioID){
            u.opcionesID=this.opcionesSeleccionadas;
            break;
          }
        }
        this.opcionesSeleccionadas=[];
        this.usuarioN={}
      }
    })
  }
}