<div class="container-md mb-5 shadow-lg">

  <!--select de los cnd-->
  <div class="container-md mt-3 shadow-lg bg-light bg-gradient" id="divCND">
    <div class="row justify-content-center justify-content-md-start">
      <div class="col">
        <div class="form-group text-center">
          <span
            class="bg-light bg-gradient text-dark d-inline-block p-3"
            style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)"
          >
            Seleccionar Destino de Guia Contenedora
          </span>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class="form-group text-center centrar">
          <select
            id="slect"
            class="form-select bg-light bg-gradient text-dark"
            [disabled]="isDisabled"
            (change)="seleccionaCND()"
            style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)"
          >
            <option
            *ngFor="let cnd of guiacontroladoralista"
            [value]="cnd.AgenciaID"
            >
              {{ cnd.RazonSocial }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

<!-- carga de guías -->
  <div
    class="container-md mt-3 shadow-lg shadow-lg bg-light bg-gradient"
    id="DivValidaPaquete"
  >
    <div class="row justify-content-center justify-content-md-start">
      <div class="col">
        <div class="form-group centrar">
          <span
            class="bg-light bg-gradient text-dark d-inline-block p-3"
            style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)"
            id="ingresonombre"
          ></span>
          <button
            type="button"
            class="rounded-circle bg-danger border-0 shadow"
            (click)="limpiarCND()"
            style="margin-left: 5px; width: 40px; height: 40px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>


  <div class="row justify-content-center justify-content-md-start">
    <div class="col">
      <div class="form-group centrar">
        <input placeholder="*Ingresar código" class="form-control" required name="codRemito" id="codRemito" type="text"
          [(ngModel)]="codigoPedido" autocomplete="off" maxlength="100" style="width: 400px;"
          (keydown.enter)="enviarCodigo()">
        <div class="spinner-border text-info" role="status" *ngIf="cargandoPedido" style="margin-left: 15px;">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>


    <div
      class="row justify-content-center justify-content-md-start"
      *ngIf="mensaje!=null && mensaje!=undefined"
    >
      <div class="col">
        <div class="form-group centrar">
          <div
            class="alert alert-dark"
            role="alert"
            style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)"
          >
            {{ mensaje }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="row justify-content-center justify-content-md-start"
      [hidden]="ishidden"
      style="margin-top: 30px"
    >
      <div class="col">
        <div class="form-group centrar">
          <button
            type="button"
            class="btn btn-info rounded-pill shadow-sm"
            style="width: 300px"
            (click)="validarOperacion()"
          >
            Validar Operación
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="container-md mt-3 shadow-lg shadow-lg bg-light bg-gradient"
    *ngIf="
      (pedidosasignados.length > 0 || pedidosError.length > 0) && !masDetalle
    "
  >
    <div class="row justify-content-center justify-content-md-start">
      <div class="col">
        <div class="form-group centrar">
          <span class="bg-light bg-gradient text-dark d-inline-block p-3"  style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)"
            >Resumen de ingreso</span
          >
        </div>
      </div>
    </div>
    <div class="row justify-content-center justify-content-md-start" style="margin-top: 30px">
      <div class="col">
        <div class="form-group text-center text-md-start">
          <span class="fs- text-success">
            PEDIDOS CARGADOS &nbsp; &nbsp; {{ pedidosasignados.length }}
          </span>
        </div>
      </div>
    </div>
    <div class="row justify-content-center justify-content-md-start">
      <div class="col">
        <div class="form-group text-center text-md-start">
          <span class="fs-5 text-warning">
            ERROR EN CARGAR &nbsp; &nbsp; {{ pedidosError.length }}
          </span>
          &nbsp;
          <button type="button" class="btn btn-link" (click)="masDetalle = true">
            <span class="fs-3 text-dark">+</span>
          </button>
        </div>
      </div>
    </div>

    <div
      class="row justify-content-center justify-content-md-start"
      style="margin-top: 30px"
    >
      <div class="col">
        <div class="form-group centrar">
          <button
            type="button"
            class="btn btn-info rounded-pill shadow-sm"
            style="width: 200px"
            [disabled]="finalizandopedido"
            (click)="finalizar()" id="btnFinaliza"
          >
            Finalizar
          </button>
          <div
            class="spinner-border text-info"
            role="status"
            *ngIf="finalizandopedido"
            style="margin-left: 15px"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <button
          type="button"
          class="btn btn-info rounded-pill shadow-sm"
          style="width: 200px"
          [disabled]="volver"
          (click)="Volver()" id="btnVolver"
        >
          Volver
        </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container-md mt-5 shadow-lg bg-light bg-gradient" *ngIf="masDetalle">
    <div class="row justify-content-center justify-content-md-start">
      <div class="col">
        <div class="form-group centrar">
          <span class="bg-light bg-gradient text-dark d-inline-block p-3"  style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)"
            >Detalle de errores</span
          >
        </div>
      </div>
    </div>
    <div
      class="row justify-content-center justify-content-md-start"
      *ngFor="let p of pedidosError"
    >
      <div class="col-8">
        <div class="form-group">{{p}}</div>
      </div>
    </div>
    <table></table>
    <div
      class="row justify-content-center justify-content-md-start"
      style="margin-top: 30px"
    >
      <div class="col">
        <div class="form-group centrar">
          <button
            type="button"
            class="btn btn-info rounded-pill"
            style="width: 300px"
            (click)="masDetalle=false"
          >
            Volver
          </button>
        </div>
      </div>
    </div>
  </div>
</div>