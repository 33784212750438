<div class="container mt-5 shadow-lg p-3 mb-5 bg-white rounded " *ngIf="loguin">
  <div *ngIf="alert" class="alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Hola Usuario !</strong> has ingresado de forma incorrecta tu usuario o contrase&ntilde;a, volve a intentar o contactate con soporte.
    <button (click)='closeAlert()' type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--Loguin Form-->

    <form [formGroup]="ingreso">
      <div class="row d-flex justify-content-center">
        <div class="col-md-6">
          <div class="card px-5 py-5" id="form1">
            <div class="form-data">

              <!--User Field-->

              <div class="forms-inputs mb-4">
                <span>Usuario</span>
                <input type="text" class="form-control" formControlName="usuario" (input)="validarIngresoUsuario($event)">
                <div class="alert alert-danger" *ngIf="(ingreso.controls['usuario'].dirty && ingreso.controls['usuario'].invalid) || usuarioVacio">
                    <span>Campo requerido</span>
                </div>
              </div>

              <!--Password Field-->

              <div class="forms-inputs mb-4">
                <span>Clave</span>
                <input  required type="password" class="form-control" formControlName="clave" (input)="validarIngresoClave($event)" ngModel>
                <div class="alert alert-danger" *ngIf="(ingreso.controls['clave'].dirty && ingreso.controls['clave'].invalid) || claveVacia">
                    <span>Campo requerido</span>
                </div>
              </div>

              <!--Submit btn-->

              <div class="mb-3" *ngIf="loguin">
                <button class="btn btn-dark w-100" (click)="ingresar()" [disabled]="">Ingresar
                  <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
</div>
