import { Injectable } from '@angular/core';
import { UrlService } from './url.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { DatosReclamo, DatosReclamoRequest, DatosReclamoTodos,AccionesCallListRequest, InsertReclamoRequest, ValidaReclamoResponse, HistoricoCallRequest } from '../models/callCenter';

@Injectable({
  providedIn: 'root'
})
export class CallcenterService {
  urlCallCenterConsulta:string=this.urlService.urlApi+"/api/CallCenter/ConsultarDatos";
  urlCallCentergetAcciones:string=this.urlService.urlApi+"/api/CallCenter/TraerAcciones";
  urlCallCenterInsert :string=this.urlService.urlApi+"/api/CallCenter/InsertarReclamo";
  urlCallCenterHistoricoCall :string=this.urlService.urlApi+"/api/CallCenter/HistoricoCall";

  constructor(private http:HttpClient, private urlService:UrlService) { }

  getDatosConsultaReclamo(nroguia:DatosReclamoRequest):Observable<DatosReclamoTodos>{
    return this.http.post<DatosReclamoTodos>(this.urlCallCenterConsulta,nroguia);
  }

  getAccionesCall(){
    return this.http.post<AccionesCallListRequest>(this.urlCallCentergetAcciones,'');
  }

  InsertReclamo(req:InsertReclamoRequest){
    return this.http.post<ValidaReclamoResponse>(this.urlCallCenterInsert,req);
  }


  
  getDatosHistoricoCall(nroguia:DatosReclamoRequest):Observable<HistoricoCallRequest>{
    return this.http.post<HistoricoCallRequest>(this.urlCallCenterHistoricoCall,nroguia);
  }
}
